/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"} description={"Chalupa nabízí ubytování (nejen) pro rodiny s dětmi, na víkendové nebo letní pobyty. Dále podkrovní sál na jógu či jiné sebe~realizační akce."} seoTitle={"Kontakt - Chalupa Ořešák u Kouřimi"} ogTitle={"Kontakt - Chalupa Ořešák u Kouřimi"} ogDescription={"Chalupa nabízí ubytování (nejen) pro rodiny s dětmi, na víkendové nebo letní pobyty. Dále podkrovní sál na jógu či jiné sebe~realizační akce."}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--80" style={{"backgroundColor":"var(--color-custom-1)"}} name={"[[UNIsecname1]]-0"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --center el--1 flex--center" anim={"2"} animS={"2"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}} animS={"3"}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":44,"paddingBottom":45}} name={"[[UNIgenericsecname2]]"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--1 flex--center" style={{"maxWidth":""}} anim={"2"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":1000}} content={"Pro ověření volného termínu a nebo ceny nám neváhejte kdykoliv zavolat.<br>Marta Nováková<br>+420 608 859 625<br>~<br>Pro více fotografií a pro načerpání atmosféry prostoru zkuste kouknout také na naše FB a IG stránky.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-19ejrvq --parallax pb--60 pt--60" name={"[[UNIsectionname3]]"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/7204c0ed55c4470cace8d7c200fd05ee_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/7204c0ed55c4470cace8d7c200fd05ee_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/7204c0ed55c4470cace8d7c200fd05ee_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/7204c0ed55c4470cace8d7c200fd05ee_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/7204c0ed55c4470cace8d7c200fd05ee_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/7204c0ed55c4470cace8d7c200fd05ee_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/7204c0ed55c4470cace8d7c200fd05ee_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/7204c0ed55c4470cace8d7c200fd05ee_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2 pb--16 pt--16" anim={"7"} animS={"2"}>
              
              <Title className="title-box" content={"<span style=\"color: white;\">+420 608 859 625<br>chalupa.oresak@gmail.com</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" style={{"backgroundColor":"var(--color-supplementary)"}} name={"[[UNIsecname7]]"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"maxWidth":""}} anim={""} animS={"2"} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">~ Udělejte si čas na sebe a své blízké ~<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":205}}>
            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">Marta Nováková<br>Nová Ves III <br>Svojšice okr. Kolín 280 02<br>+420 608 859 625<br>chalupa.oresak@gmail.com<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}